<template>
	<div class="mt-4">
		<FeedNewPost :course="course" />

		<FeedPosts :posts="posts" :page="page" :per-page="perPage" :pages="pages" />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: `${this.$t('feed.posts')} - ${this.course.name}`,
		}
	},
	components: {
		FeedNewPost: () => import('@/components/feed/FeedNewPost.vue'),
		FeedPosts: () => import('@/components/feed/FeedPosts.vue'),
	},
	data() {
		return {
			page: 1,
			perPage: 10,
			pages: 1,
		}
	},
	computed: {
		...mapGetters({
			course: 'courses/course',
			posts: 'feed/posts',
			setupInfo: 'user/setupInfo',
		}),
	},
	watch: {
		course() {
			this.page = 1
			this.pages = 1
			this.updateList(this.page, this.perPage)
		},
	},
	created() {
		this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
		this.perPage = this.$route.query.perPage ? parseInt(this.$route.query.perPage) : 10
		this.updateList(this.page, this.perPage)
	},
	methods: {
		updateList(toPage, perPage) {
			if (this.setupInfo.accountType.student.value) {
				this.$router.replace({ name: this.$route.name, query: { page: toPage, perPage } })
				this.fetchPosts({ courseID: this.course.id, page: toPage, perPage }).then(({ pages }) => {
					this.page = toPage
					this.perPage = perPage
					this.pages = pages
				})
			}
		},
		...mapActions('feed', ['fetchPosts', 'publishPost']),
	},
}
</script>

<style scoped></style>
